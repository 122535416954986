@-webkit-keyframes btncolor {
  0% {
	color:#fff;
	transition: all 1s ease-in;
  }
  50% {  
	color:#fcff00;
	transition: all 1s ease-in;
    background-color:#fcff00;
  } 
  100% {
	color:#fff;
	transition: all 1s ease-in;
  }  
}
@keyframes btncolor {
  0% {
	color:#fff;
	transition: all 1s ease-in;
  }
  50% {  
	color:#fcff00;
	transition: all 1s ease-in;
    background-color:#fcff00;
  } 
  100% {
	color:#fff;
	transition: all 1s ease-in;
  }  
}
.influencer-page .main-content .page-width{
  max-width: 100%!important;
  padding:0px!important;  
}
.influencer-page .section-header{
  display:none;
}
.auto-margin{
	width:85%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	display: table;
}
.block{
	display: table;
	width: 100%;
}
.influencer-hero{
    padding:  5% 0px 125px 0px;
    background-image: url(https://cdn.shopify.com/s/files/1/0580/4356/4230/t/10/assets/hero-bg-1.png);
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  
}
.terry15 .influencer-hero{
    padding:  5% 0px 225px 0px; 
}
.col,
.col-30,
.col-40,
.col-50,
.col-60{
	float:left;
}
.col-50{
	width: 49.8%;
}
.padd-equal{
    padding: 70px 0px;
}
.button{
	display: inline-block;
	font-weight: 700;
	font-size: 16px;
	text-decoration: none!important;
	padding-right: 40px;
	padding-left: 40px;
	padding-top: 13px;
	padding-bottom: 13px;
	text-transform: uppercase;
	border:none;
	border-radius:0px;
}
.img-fixed{
    width: 49.8%;
    position: absolute;
    top: 50px;
    right: 0px;
}
.influencer-hero h1{
    color: #fff;
}
.influencer-hero .hero-content{
    color: #fff;
    font-size: 16px;
    line-height: 26px;
    padding: 15px 0px;
}
.influencer-hero .button,
.buy-spray .button{
    color: #fff!important;
    background-color: #008080;
}
.influencer-hero .button:hover,
.buy-spray .button{
    color: #fff!important;
    background-color: #01a1a1;
}
.buy-spray .block-btn .button{
  font-size: 24px!important;
  font-weight:700!important;
  background-color: #0e1d94!important;
  border-top-right-radius:7px;
  border-bottom-left-radius:7px;
  -webkit-animation: btncolor 3s infinite; 
  animation: btncolor 3s infinite; 
  position:relative!important;
}
.buy-spray .block-btn .button:hover{
  background-color: #1e37fd!important;
}
.disctount-txt,
.buy-spray .text-block p{
  color:#fff;
  font-size:24px;
  line-height:32px;
  font-weight:700;
  padding: 20px;
  background-color: #008080;
  border-top-left-radius:0px;
  border-top-right-radius:15px;
  border-bottom-left-radius:15px;
  border-bottom-right-radius:15px;
  
}
/*
.influencer-hero .button{
    color: #000!important;
    background-color: #fff;
}
.influencer-hero .button:hover{
    color: #fff!important;
    background-color: #001dff;
}*/
@-webkit-keyframes floatspray {
0% {
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
}
50% {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
100% {
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
}
}
@keyframes floatspray {
0% {
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
}
50% {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
100% {
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
}
}
.influencer-hero .img-col,
.spray-features .img-spray{
    animation-name: floatspray;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: floatspray;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: floatspray;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: floatspray;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: floatspray;
  -o-animation-duration: 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

.influencer-hero .img-col img{
    width: 80%;
    max-width: 350px;
    height: auto;
    margin: auto;
    display: block;
    border:solid 10px #fff;
    border-radius: 50%;
    box-shadow: 0px 0px 40px rgba(0,29,255,0.9); 
}
.spray-features{
  position: relative;
  margin:0px 0px 150px 0px!important;
}
.spray-features .img-spray img{
    width: 80%;
    max-width: 450px;
    height: auto;
    margin: 0px auto;
    display: block;
}
.quote-box{
  font-size:24px;
  font-weight:700;
  padding:15px;
  border-left:solid 5px #008080;
}
.quote-box p:first-child{
  position:relative;
  padding:40px 60px 15px 60px;
  display:block;
}
.quote-box p:first-child::before{
  content:"\275D";
  font-size:100px;
  font-weight:400;
  width:75px;
  height:75px;
  position: absolute;
  top:-15px;
  left:0px;
}
.quote-box p:first-child::after{
  content:"\275E";
  font-size:100px;
  font-weight:400;
  width:75px;
  height:75px;
  position: absolute;
  bottom:-20px;
  right:10px;
}
.buy-spray{
    padding:0px 0px 50px 0px;
}
.buy-spray .col-content{
    height: 500px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
}
.buy-spray .img-col img{
    width: 80%;
    max-width: 450px;
    height: auto;
    margin: 0px 0px;
    display: block;
    border-radius: 30px;
}
.buy-spray ul,
.spray-features ul{
    padding: 0px;
    margin: 0px;
}
.buy-spray ul li,
.spray-features ul li{
    padding: 0px 0px 10px 25px;
    list-style-type: none;
    position: relative;
}
.buy-spray ul li::before,
.spray-features ul li::before{
    color: #0e1d94;
    font-size: 20px;
    content: "\2714";
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 10;
    top: 0px;
    left: 0px;
    background-color: rgba(255,255,255,0);
}
.app-links-table{
  border:none!important;
}
.app-block{
  padding: 20px 0px;
}
.inline-img-block{
  display:inline-block;
  vertical-align:top;
  margin:0px 0px 0px 20px;
}
.inline-img-block img{
  width:auto;
  height:67px;
}
@media screen and (max-width:768px){
.col,
.col-30,
.col-40,
.col-50,
.col-60{
    width:100%;
	float:none;
}
.padd-equal{
    padding: 50px 0px;
}
.influencer-hero{
    padding: 13% 0px;
    background-position: center center;
    background-size: cover;
}    
.influencer-hero .img-col img{
    max-width: 250px;
    display: block;
    margin: 0px auto 30px auto;
}
.img-fixed{
    width: 100%;
    position: static;
}
.spray-features{
  margin:0px 0px 30px 0px!important;
}
.spray-features .no-stacking .col-50{
    width:49.8%;
	float:left;
}
.buy-spray .col-content{
    height: auto;
    display: block;
}
.buy-spray h3{
    padding-top:20px;
}
}
@media screen and (max-width:600px){
.spray-features .no-stacking .col-50{
    width:100%;
	float:none;
}
.buy-spray .img-col img{
    width: 85%;
    margin: 0px auto;
}
}